<template>
  <v-row wrap no-gutters>
    <credentials-select
      :key="`${step.id}-credentialname`"
      step-type="OUTLOOK_CALENDAR"
      :properties="properties"
      step-label="outlook-calendar"
      :readonly="!canEdit"
      required
      @change="handleChange('credentialName', $event)"
    />

    <v-col cols="12">
      <action-select
        :key="`${step.id}-action`"
        :items="formattedOutlookCalendarActions"
        :default-value="properties.action"
        step-label="outlook-calendar"
        :readonly="!canEdit"
        required
        @change="handleChange('action', $event)"
      />
    </v-col>

    <v-col
      v-if="properties.action !== 'AUTH'"
      cols="12"
    >
      <v-text-field
        :key="`${step.id}-calendarId`"
        v-model="properties.fields.calendarId"
        outlined
        dense
        :label="$lang.labels.calendarId"
        :required="false"
        :readonly="!canEdit"
        data-cy="outlook_calendar-calendarId"
      />
      <v-text-field
        :key="`${step.id}-userId`"
        v-model="properties.fields.userId"
        outlined
        dense
        :label="$lang.labels.userId"
        required
        :rules="[v => !!v || $lang.labels.required]"
        class="required-asterisk"
        :readonly="!canEdit"
        data-cy="outlook_calendar-userId"
      />
    </v-col>

    <v-col
      v-if="['DELETE', 'UPDATE'].includes(properties.action)"
      cols="12"
    >
      <v-text-field
        :key="`${step.id}-eventId`"
        v-model="properties.fields.eventId"
        outlined
        dense
        :label="$lang.labels.eventId"
        required
        :rules="[v => !!v || $lang.labels.required]"
        :readonly="!canEdit"
        class="required-asterisk"
        data-cy="outlook_calendar-eventId"
      />
    </v-col>

    <v-col
      v-if="properties.action === 'AUTH'"
      cols="12"
    >
      <v-text-field
        :key="`${step.id}-expiresIn`"
        v-model="properties.fields.expiresIn"
        outlined
        dense
        type="number"
        :label="$lang.labels.expiresIn"
        :required="false"
        :readonly="!canEdit"
        data-cy="outlook_calendar-expiresIn"
        @input="handleChange('fields.expiresIn', $event)"
      />
    </v-col>

    <v-col
      v-if="['CREATE', 'UPDATE'].includes(properties.action)"
      cols="12"
    >
      <v-text-field
        :key="`${step.id}-subject`"
        v-model="properties.fields.subject"
        outlined
        dense
        :label="$lang.labels.subject"
        required
        :rules="[v => !!v || $lang.labels.required]"
        :readonly="!canEdit"
        class="required-asterisk"
        data-cy="outlook_calendar-subject"
      />
      <v-select
        :key="`${step.id}-contentType`"
        v-model="properties.fields.body.contentType"
        full-width
        dense
        required
        :rules="[v => !!v || $lang.labels.required]"
        class="required-asterisk"
        outlined
        :items="OUTLOOK_CALENDAR_CONTENT_TYPES"
        :readonly="!canEdit"
        :label="$lang.labels.contentType"
        data-cy="outlook_calendar-contentType"
      />
      <v-textarea
        :key="`${step.id}-content`"
        v-model="properties.fields.body.content"
        outlined
        dense
        :label="$lang.labels.content"
        required
        :rules="[v => !!v || $lang.labels.required]"
        class="required-asterisk"
        :readonly="!canEdit"
        data-cy="outlook_calendar-content"
      />
      <text-input-with-clock-button
        :key="`${step.id}-start`"
        :default-value="properties.fields.start.dateTime"
        :label="$lang.labels.startTime"
        step-label="outlook_calendar"
        required
        :rules="[v => !!v || $lang.labels.required]"
        class="required-asterisk"
        :readonly="!canEdit"
        @change="handleChange('fields.start.dateTime', $event)"
        @openDateTimeModal="$emit('openDateTimeModal', 'fields.start.dateTime')"
      />
      <text-input-with-clock-button
        :key="`${step.id}-end`"
        :default-value="properties.fields.end.dateTime"
        :label="$lang.labels.endTime"
        step-label="outlook_calendar"
        required
        :rules="[v => !!v || $lang.labels.required]"
        class="required-asterisk"
        :readonly="!canEdit"
        @change="handleChange('fields.end.dateTime', $event)"
        @openDateTimeModal="$emit('openDateTimeModal', 'fields.end.dateTime')"
      />
      <v-text-field
        :key="`${step.id}-location`"
        v-model="properties.fields.location"
        outlined
        dense
        :label="$lang.labels.location"
        :required="false"
        :readonly="!canEdit"
        data-cy="outlook_calendar-location"
      />
      <calendar-participants-name-email-list
        :key="`${step.id}-attendees`"
        step-type="OUTLOOK_CALENDAR"
        :can-edit="canEdit"
        :data="properties.fields.attendees"
        :single-step="step"
        :steps="steps"
        :header-label="$lang.labels.attendees"
        has-optional-checkbox
        class="mb-2"
        @dataChanged="handleChange('fields.attendees', $event)"
      />
      <v-text-field
        :key="`${step.id}-reminderMinutesBeforeStart`"
        v-model="properties.fields.reminderMinutesBeforeStart"
        outlined
        dense
        type="number"
        :label="$lang.labels.reminderMinutesBeforeStart"
        :required="false"
        :readonly="!canEdit"
        data-cy="outlook_calendar-reminderMinutesBeforeStart"
      />
      <v-combobox
        :key="`${step.id}-recurrencePattern`"
        v-model="properties.fields.recurrence.pattern.type"
        outlined
        dense
        clearable
        :items="SUPPORTED_RECURRING_TYPES"
        :label="$lang.labels.reminderRecurrencePattern"
        :readonly="!canEdit"
        required
        :rules="[v => !!v || $lang.labels.required]"
        class="required-asterisk"
        data-cy="outlook_calendar-recurrencePattern"
        @change="handleChange('fields.recurrence.pattern.type', $event)"
      />
      <v-text-field
        :key="`${step.id}-recurrenceInterval`"
        v-model="properties.fields.recurrence.pattern.interval"
        outlined
        dense
        type="number"
        :label="$lang.labels.reminderRecurrenceInterval"
        required
        :rules="[v => !!v || $lang.labels.required]"
        class="required-asterisk"
        :readonly="!canEdit"
        data-cy="outlook_calendar-recurrenceInterval"
      />
      <v-combobox
        :key="`${step.id}-daysOfWeek`"
        v-model="properties.fields.recurrence.pattern.daysOfWeek"
        :items="WEEK_DAYS"
        outlined
        dense
        multiple
        chips
        deletable-chips
        :label="$lang.labels.reminderDaysOfWeek"
        :readonly="!canEdit"
        required
        :rules="[v => !!v || $lang.labels.required]"
        class="days-of-week-combobox required-asterisk"
        @input="handleChange('fields.recurrence.pattern.daysOfWeek', $event)"
      />
      <text-input-with-clock-button
        :key="`${step.id}-recurrenceRangeStart`"
        :default-value="properties.fields.recurrence.range.startDate"
        :label="$lang.labels.reminderRecurrenceRangeStart"
        step-label="outlook_calendar"
        :readonly="!canEdit"
        required
        :rules="[v => !!v || $lang.labels.required]"
        class="required-asterisk"
        @change="handleChange('fields.recurrence.range.startDate', $event)"
        @openDateTimeModal="$emit('openDateTimeModal', 'fields.recurrence.range.startDate')"
      />
      <text-input-with-clock-button
        :key="`${step.id}-recurrenceRangeEnd`"
        :default-value="properties.fields.recurrence.range.endDate"
        :label="$lang.labels.reminderRecurrenceRangeEnd"
        step-label="outlook_calendar"
        :readonly="!canEdit"
        required
        :rules="[v => !!v || $lang.labels.required]"
        class="required-asterisk"
        @change="handleChange('fields.recurrence.range.endDate', $event)"
        @openDateTimeModal="$emit('openDateTimeModal', 'fields.recurrence.range.endDate')"
      />
    </v-col>

    <v-col v-if="['CREATE', 'UPDATE', 'LIST'].includes(properties.action)" cols="12">
      <v-text-field
        :key="`${step.id}-targetObject`"
        v-model="properties.fields.targetObject"
        outlined
        dense
        :label="$lang.labels.targetObject"
        :required="properties.action === 'LIST'"
        :rules="properties.action === 'LIST' ? [(v) => $options.filters.javaVariableConventionRules(v, true) || $lang.errors.variableJavaWrong] : []"
        :class="properties.action === 'LIST' && 'required-asterisk'"
        :readonly="!canEdit"
      />
    </v-col>
  </v-row>
</template>

<script>
import {
  ActionSelect,
  CredentialsSelect,
  TextInputWithClockButton
} from './components'
import CalendarParticipantsNameEmailList from '../CalendarParticipantsNameEmailList.vue'
import StepMixin from './StepMixin'

import { OUTLOOK_CALENDAR_ACTIONS, OUTLOOK_CALENDAR_CONTENT_TYPES } from './constants'
import { SUPPORTED_RECURRING_TYPES, WEEK_DAYS } from '@/utils/constants'
import { getNestedPropertyForSetting } from '@/utils/helpers'

export default {
  name: 'OutlookCalendarStep',
  components: {
    ActionSelect,
    CredentialsSelect,
    TextInputWithClockButton,
    CalendarParticipantsNameEmailList
  },
  mixins: [StepMixin],
  props: {
    steps: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      OUTLOOK_CALENDAR_CONTENT_TYPES,
      WEEK_DAYS,
      SUPPORTED_RECURRING_TYPES,
      currentTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
    }
  },
  computed: {
    formattedOutlookCalendarActions() {
      return OUTLOOK_CALENDAR_ACTIONS.map((x) => {
        return { text: this.$lang.status[x], value: x }
      })
    }
  },
  created() {
    if (!this.properties.fields) this.$set(this.properties, 'fields', {})
    if (this.properties.fields?.attendees?.length) {
      this.properties.fields.attendees.forEach((attendee) => {
        attendee.email = attendee.address
      })
    }
  },
  methods: {
    handleChange(key, val) {
      if (['CREATE', 'UPDATE'].includes(val) && key === 'action') {
        if (!this.properties.fields?.body) this.$set(this.properties.fields, 'body', {})
        if (!this.properties.fields?.start) this.$set(this.properties.fields, 'start', {
          dateTime: '',
          timeZone: this.currentTimeZone
        })
        if (!this.properties.fields?.end) this.$set(this.properties.fields, 'end', {
          dateTime: '',
          timeZone: this.currentTimeZone
        })
        if (!this.properties.fields?.attendees) this.$set(this.properties.fields, 'attendees', [])
        if (!this.properties.fields?.recurrence) this.$set(this.properties.fields, 'recurrence', {
          pattern: {},
          range: {}
        })
      }

      if (key.includes('attendees') && val?.length) {
        val.forEach((attendee) => {
          attendee.address = attendee.email || ''
        })
      }
      // if (key.includes('expiresIn')) {
      //   val = Number(val)
      // }
      
      if (key.includes('.')) {
        const { nestedObj, lastKey } = getNestedPropertyForSetting(this.properties, key)

        this.$set(nestedObj, lastKey, val)

        return
      }
      this.$set(this.properties, key, val)
    }
  }
}
</script>

<style lang="scss" scoped>
.days-of-week-combobox::v-deep {
  .v-chip--select {
    height: 24px;
  }
}
</style>
